<script>
  import {
    faHeart,
    faHourglass,
    faQuestionCircle,
  } from "@fortawesome/free-regular-svg-icons";
  import {
    faBrain,
    faHands,
    faMugHot,
    faStar,
  } from "@fortawesome/free-solid-svg-icons";
  import { flip } from "svelte/animate";
  import { fade, fly } from "svelte/transition";

  import Container from "../components/Container.svelte";
  import Icon from "../components/Icon.svelte";
  import Section from "../components/Section.svelte";

  const answers = ["Rarely", "Sometimes", "Most of the time"];

  const colors = ["#f7322c", "#ffcf59", "#1fbc1f"];

  const ratingKeys = [
    "Focus here first",
    "Room for improvement",
    "You're doing well",
  ];

  const sections = [
    {
      name: "Creating A Safe Environment",
      icon: faMugHot,
      statements: [
        "I give the conversation the amount of time it truly needs",
        "I ask the other person if it's a good time for the conversation",
        "I give context rather than merely improvising",
      ],
    },
    {
      name: "Removing Distractions & Focusing",
      icon: faHourglass,
      statements: [
        "I listen through to the end of each thing the other person says",
        "I remain alert and engaged during meetings or presentations",
        "I take notes when necessary to help me listen better",
        "I refrain from multitasking while I'm in a conversation",
      ],
    },
    {
      name: "Seeking Deep Understanding",
      icon: faBrain,
      statements: [
        "I avoid thinking about what I am going to say while the other person is speaking",
        "I let the other person finish before speaking",
        "I assume I don't know what the other person is going to say",
        "When my mind is elsewhere, I stop the conversation",
        "I check for understanding to make sure I am getting my message across to the other person",
        "I repeat instructions or messages to make sure I've understood them correctly",
        "I give other people advice only when I'm asked for it",
        "I ask about the meaning of unfamiliar words or acronyms",
      ],
    },
    {
      name: "Accounting For Non-verbal Cues",
      icon: faHands,
      statements: [
        "I am comfortable when silence emerges in the conversation",
        "I keep good eye contact with the person who is speaking",
        "I listen for the meaning behind the speaker's words through their gestures and facial expressions",
        "I take into consideration the emotional condition of the person I am talking to (nervous, stressed, distracted, etc.)",
        "I pick up on subtle details of what is going on in the speaker's personal life",
      ],
    },
    {
      name: "Understanding Feelings Vs Facts",
      icon: faHeart,
      statements: [
        "I pay just as much attention to people who disagree with me",
        "I try to learn something from each person I meet",
        "I listen to the speaker without judging or criticizing them",
        "I accept critical feedback without becoming defensive or physically tense",
      ],
    },
    {
      name: "Supporting Others By Coaching Them",
      icon: faQuestionCircle,
      statements: [
        "I reframe things to help the other person explore the topic more deeply",
        "I use open, specific questions",
        "I help the other person determine their next steps",
      ],
    },
  ];

  let sectionId = 0;
  let statementId = 0;

  let progress = 1;
  const progressMax = sections.reduce(
    (sum, sec) => sum + sec.statements.length,
    0
  );

  const responses = {};
  let showResult = false;
  let showResultReady = false;

  const secLengthsById = Object.fromEntries(
    sections.map((sec, i) => [i, sec.statements.length])
  );

  function onRespond(rating) {
    const statement = sections[sectionId].statements[statementId];
    responses[statement] = rating;

    statementId += 1;
    if (statementId >= secLengthsById[sectionId]) {
      sectionId += 1;
      statementId = 0;
    }

    if (sectionId >= sections.length) {
      showResult = true;
    }

    progress += 1;
  }

  // Test last page
  // while (showResult == false) {
  //   onRespond(Math.round(Math.random() * 2));
  // }
  // showResultReady = true;
</script>

<Section bgColor="dark-grey">
  <Container>
    <h1>Listening Skills Assessment</h1>
    <p>
      Reflect on how you can become a more empathetic, perceptive listener and
      coach.
    </p>
  </Container>
</Section>

{#if !showResult}
  <Section>
    <Container>
      <progress
        class="progress is-small is-warning overall"
        value={progress}
        max={progressMax}
      >
        {progress}/{progressMax}
      </progress>
      {#each [sections[sectionId]] as section (sectionId)}
        <div
          in:fade={sectionId > 0 ? { duration: 500 } : { duration: 0 }}
          out:fade={sectionId == sections.length - 1
            ? { duration: 500 }
            : { duration: 0 }}
          on:outroend={() => (showResultReady = showResult)}
        >
          <h2>
            Part {sectionId + 1}:
            {section.name}
          </h2>

          {#each [section.statements[statementId]] as statement (statementId)}
            <div
              animate:flip={statementId > 0}
              in:fly={statementId > 0
                ? { x: 200, duration: 500 }
                : { duration: 0 }}
              out:fly={statementId > 0 &&
              statementId < secLengthsById[sectionId] - 1
                ? { x: -200, duration: 500 }
                : { duration: 0 }}
              class="step"
            >
              <p class="statement">{statement}...</p>
              <table class="rating">
                {#each answers as answer, i}
                  <tr on:click={() => onRespond(i)}>
                    <td class="star" style="color:{colors[i]}; opacity:0.3">
                      <Icon i={faStar} />
                    </td>
                    <td>
                      {answer}
                    </td>
                  </tr>
                {/each}
              </table>
            </div>
          {/each}
        </div>
      {/each}
    </Container>
  </Section>
{/if}

{#if showResultReady}
  <Section>
    <Container>
      <div in:fade={{ duration: 500 }}>
        <h2>Results</h2>
        <div class="key">
          <ul>
            {#each ratingKeys as cta, c}
              <li style="background-color: {colors[c]}">{cta}</li>
            {/each}
          </ul>
        </div>
        <div class="results color-bg-snow">
          {#each sections as sec}
            <h3>
              <Icon i={sec.icon} />
              {sec.name}
            </h3>
            <ul>
              {#each sec.statements as state}
                <li class="bullet-{responses[state]}">
                  {state}
                </li>
              {/each}
            </ul>
          {/each}
        </div>
      </div>

      <div class="ctas">
        <p>
          If becoming a better listener and coach sounds valuable, check out our
          team workshops on these subjects (which feature
          <a href="/post/learning-to-listen-deeply" target="_blank"
            >this simple exercise</a
          >)...
        </p>

        <div class="buttons">
          <a
            class="button is-medium is-gold"
            href="/w/coaching-workshop"
            target="_blank"
          >
            Coaching
          </a>
          <a
            class="button is-medium is-gold"
            href="/w/1on1s-and-pdps"
            target="_blank"
          >
            Effective 1-on-1s
          </a>
        </div>
      </div>
    </Container>
  </Section>
{/if}

<style>
  .step {
    border: 1px solid var(--ps-colors-light-grey);
    border-radius: 5px;
    margin: 0 auto;
    max-width: 600px;
    padding: 1rem;
  }

  .step .statement {
    font-weight: bold;
  }

  .rating {
    margin: 0 auto;
  }

  .rating td {
    padding: 10px 5px;
    text-align: left;
    text-decoration: underline;
    text-decoration-color: var(--ps-colors-light-grey);
  }

  .rating tr:hover * {
    cursor: pointer;
    font-weight: bold;
    opacity: 1 !important;
  }

  .key {
    margin: 0 auto 1.5rem;
    width: fit-content;
  }

  .key ul {
    width: fit-content;
  }

  .key ul li {
    border-radius: 5px;
    color: black;
    font-size: var(--ps-fontsize-tiny);
    font-weight: bold;
    margin: 5px 10px;
    opacity: 75%;
    padding: 10px 20px;
  }

  .results {
    margin: 0 auto;
    max-width: 680px;
    padding: 1em 1.5em;
    text-align: left;
  }

  .results h3 {
    display: flex;
    font-size: var(--ps-fontsize-small);
    margin-bottom: 0.75rem;
  }

  .results h3 :global(.icon) {
    margin-right: 0.5rem;
  }

  .results ul {
    margin-bottom: 2rem;
  }

  .results ul li {
    list-style-type: disc;
    margin-bottom: 0.5rem;
    margin-left: 2.5rem;
  }

  .results ul li::marker {
    font-size: 1.5em;
  }

  .results ul li.bullet-0::marker {
    color: #f7322c;
  }
  .results ul li.bullet-1::marker {
    color: #ffcf59;
  }
  .results ul li.bullet-2::marker {
    color: #1fbc1f;
  }

  .ctas {
    margin: 2rem auto 0;
    max-width: 600px;
  }

  .ctas a:not(.button) {
    text-decoration-line: underline;
  }

  .buttons {
    justify-content: center;
  }

  h2 {
    font-size: var(--ps-fontsize-medlarge);
    margin-bottom: 1.5rem;
  }

  progress.overall {
    margin: 0 auto 1.5rem;
    width: revert;
  }

  @keyframes bgBounceThroughGold {
    0% {
      background-color: inherit;
    }
    50% {
      background-color: var(--ps-colors-gold);
    }
    100% {
      background-color: inherit;
    }
  }

  .flashGold {
    animation: bgBounceThroughGold 1s;
  }
</style>
