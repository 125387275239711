<script>
  import { faHeadphones } from "@fortawesome/free-solid-svg-icons";

  import Icon from "../components/Icon.svelte";
  import IntOrExtLink from "./IntOrExtLink.svelte";

  export let post;
</script>

<div class="card">
  <IntOrExtLink link={post.link}>
    <img alt={post.thumbAlt} src={post.thumbLink} />
    <h3>
      {post.title}
    </h3>
    <p class="metadata">
      {post.date}
      {#if post.wordCount}
        &middot; {Math.round(post.wordCount / 200) || 1}m read
      {/if}
      {#if post.audio}
        <Icon i={faHeadphones} />
      {/if}
    </p>
    <p class="summary">
      {post.summary}
    </p>
    {#if post.hostedBy}
      <p class="hosted">
        hosted by {post.hostedBy}
      </p>
    {/if}
  </IntOrExtLink>
  <div />
</div>

<style>
  .card {
    border-radius: 5px;
    width: 100%;
  }

  .card h3,
  .card p {
    margin: 0;
    padding: 0 1rem;
    width: 100%;
  }

  .card h3 {
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }

  .card img {
    height: 15vw;
    object-fit: cover;
    width: 100%;
  }

  .card p.metadata {
    color: var(--ps-colors-mid-grey);
    font-size: var(--ps-fontsize-tiny);
    margin-bottom: 1rem;
  }

  .card p.hosted {
    color: var(--ps-colors-mid-grey);
    font-size: var(--ps-fontsize-tiny);
    font-style: italic;
    margin-bottom: 1rem;
  }

  .card p.summary {
    margin-bottom: 1rem;
  }

  .card :global(.icon) {
    vertical-align: bottom;
  }

  /* TODO: hardcoded breakpoint */
  @media screen and (max-width: 768px) {
    .card img {
      height: auto;
      max-height: 300px;
    }
  }
</style>
