<script>
  import { airtableData } from "../airtable/compiled";
  import { posts } from "../blog/facade";
  import BlogPostCard from "../components/BlogPostCard.svelte";
  import Container from "../components/Container.svelte";
  import NavLink from "../components/NavLink.svelte";
  import Section from "../components/Section.svelte";
  import YouTubeVideo from "../components/YouTubeVideo.svelte";
  import { undrawImageAlt } from "../undrawImageAlts";

  const items = airtableData.readingListItems;

  const postStubsWanted = new Set([
    "consensus-vs-consent",
    "10-leadership-lessons-from-ted-lasso",
    "5-ways-to-embrace-playful-leadership",
    "stitch-in-time",
  ]);

  const videos = [
    { id: "gCfzeONu3Mo", title: "How Miscommunication Happens" },
    { id: "rbR2V1UeB_A", title: "Autonomy, Mastery & Purpose" },
    { id: "uJKDipbCzdc", title: "Great Leaders Take Humor Seriously" },
    { id: "kJdXjtSnZTI", title: "Peformance vs Trust" },
  ];

  const postSlices = posts(true)
    .filter((post) => postStubsWanted.has(post.stub))
    .reduce((slices, post, i) => {
      const sliceId = Math.floor(i / 3);
      slices[sliceId] = (slices[sliceId] || []).concat([post]);
      return slices;
    }, []);
</script>

<Section bgColor="dark-grey">
  <Container>
    <img
      alt={undrawImageAlt("files1_9ool")}
      class="head mb1r"
      height="778"
      src="/img/illustrations/gold/undraw_bibliophile_hwqc.svg"
      width="814"
    />
    <h1>Leadership Resource List</h1>
    <p class="strapline">
      {items.length + postStubsWanted.size + videos.length} things we recommend checking
      out as part of our <NavLink
        style="text-decoration-line:underline"
        to="/p/leadership-program"
      >
        leadership program
      </NavLink>
    </p>
  </Container>
</Section>

<Section>
  <Container>
    <div class="columns is-multiline">
      {#each items as item}
        <div class="column is-one-third">
          <img
            alt={item.title.toLowerCase().replaceAll(":", "-")}
            class="mb1r"
            src={"/img/books/" +
              item.title
                .toLowerCase()
                .replaceAll(":", "-")
                .replaceAll(" ", "-") +
              ".jpg"}
            style="height:200px"
          />
          <h3>{item.title}</h3>
          <p>{item.description}</p>
          <!-- <div class="buttons">
            <a
              aria-label="Amazon USA"
              class="button is-gold"
              href={item.usUrl}
              rel="noopener"
              target="_blank"
            >
              <Icon i={faDollarSign} />
            </a>
            <a
              aria-label="Amazon UK"
              class="button is-gold"
              href={item.ukUrl}
              rel="noopener"
              target="_blank"
            >
              <Icon i={faPoundSign} />
            </a>
          </div> -->
        </div>
      {/each}
    </div>
  </Container>
</Section>

<Section>
  <Container>
    {#each postSlices as slice}
      <div class="columns">
        {#each slice as post}
          <div class="blog-post column is-one-third">
            <BlogPostCard {post} />
          </div>
        {/each}
      </div>
    {/each}
  </Container>
</Section>

<Section>
  <Container>
    <div class="columns is-multiline">
      {#each videos as { id, title }}
        <div class="video column is-one-third">
          <YouTubeVideo {id} {title} />
          <h3 style="margin-top: 0.5rem;">{title}</h3>
        </div>
      {/each}
    </div>
  </Container>
</Section>

<!-- <Section>
  <Container>
    <p class="footnote">
      This page contains affiliate links. If you purchase a book we recommend,
      we may receive a small consideration in return.
    </p>
  </Container>
</Section> -->

<style>
  /* a.button {
    margin-bottom: 1.5rem;
  } */

  .blog-post {
    padding: 1rem;
    text-align: left;
  }

  img.head {
    max-height: 200px;
  }

  /* p.footnote {
    font-size: var(--ps-fontsize-tiny);
    font-style: italic;
  } */
</style>
