<script>
  import { faCaretLeft, faCaretRight } from "@fortawesome/free-solid-svg-icons";
  import { onMount } from "svelte";
  import { flip } from "svelte/animate";
  import { fly } from "svelte/transition";

  import { carouselPosition } from "../stores";
  import { getActiveRoute } from "../utils";

  import Icon from "../components/Icon.svelte";

  export let collapseWidth = 768; // TODO: hardcoded breakpoint
  export let component;
  export let defaultCount = 3;
  export let items;
  export let pageKeyAdd = "";
  export let style;

  const activeRoute = getActiveRoute();
  const pageKey = $activeRoute.uri + pageKeyAdd;
  $carouselPosition[pageKey] = $carouselPosition[pageKey] || 0;

  let animDir = 1;
  let width;
  let windowWidth;

  function left() {
    animDir = -1;
    $carouselPosition[pageKey] -= 1;
  }

  function right() {
    animDir = 1;
    $carouselPosition[pageKey] += 1;
  }

  $: count = windowWidth <= collapseWidth ? 1 : defaultCount;
  $: disableLeft = $carouselPosition[pageKey] == 0;
  $: disableRight = $carouselPosition[pageKey] == items.length - count;

  $: {
    if ($carouselPosition[pageKey] < 0) $carouselPosition[pageKey] = 0;
    else if ($carouselPosition[pageKey] > items.length - count)
      $carouselPosition[pageKey] = items.length - count;
  }

  export let auto;
  if (auto) {
    onMount(() => {
      let secsElapsed = 0;

      const interval = setInterval(() => {
        secsElapsed += 1;
        if (secsElapsed < 5) return;

        secsElapsed = 0;
        if ($carouselPosition[pageKey] == items.length - count) {
          animDir = -1;
          $carouselPosition[pageKey] = 0;
        } else {
          right();
        }
      }, 1000);

      return () => clearInterval(interval);
    });
  }
</script>

<svelte:window bind:innerWidth={windowWidth} />

{#if !auto}
  <div class="buttons is-centered">
    <button
      aria-label="Left"
      class="button is-dark"
      disabled={disableLeft}
      on:click={left}>
      <Icon i={faCaretLeft} />
    </button>
    <button
      aria-label="Right"
      class="button is-dark"
      disabled={disableRight}
      on:click={right}>
      <Icon i={faCaretRight} />
    </button>
  </div>
{/if}

<div class="columns" {style} bind:clientWidth={width}>
  {#each items.slice($carouselPosition[pageKey], $carouselPosition[pageKey] + count) as item, i ($carouselPosition[pageKey] + i)}
    <div
      animate:flip={{ duration: 200 }}
      class="column"
      in:fly={{ x: (animDir * width) / count }}>
      <svelte:component this={component} {item} />
    </div>
  {/each}
</div>
