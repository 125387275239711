<script>
  import {
    faFacebookF,
    faLinkedin,
    faTwitter
  } from "@fortawesome/free-brands-svg-icons";

  import Icon from "./Icon.svelte";
</script>

<a
  aria-label="facebook"
  href="https://www.facebook.com/PeopleStorming"
  rel="noopener"
  target="_blank">
  <Icon i={faFacebookF} />
</a>
<a
  aria-label="twitter"
  href="http://www.twitter.com/peoplestorming"
  rel="noopener"
  target="_blank">
  <Icon i={faTwitter} />
</a>
<a
  aria-label="linkedin"
  href="http://www.linkedin.com/company/peoplestorming"
  rel="noopener"
  target="_blank">
  <Icon i={faLinkedin} />
</a>
